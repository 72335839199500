<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>

    <v-form
      ref="form"
      class="row"
    >
      <input-text
        v-model="form.descricao"
        class="col-12"
        :label="$t('modulos.tipo_setor.formulario.nome')"
        obrigatorio
        :max="100"
      />
      <!-- <input-select
        v-model="form.posicao"
        class="col-4"
        :label="$t('modulos.tipo_setor.formulario.posicao')"
        obrigatorio
        :options="opcoes.posicoes"
      /> -->
    </v-form>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import TipoSetoresService from '@common/services/cadastros/TipoSetoresService.js';
import { TipoSetorModel } from '@common/models/cadastros/TipoSetorModel.js';
import helpers from '@common/utils/helpers';
export default {
  props: ['id'],
  data() {
    return {
      valido: false,
      form: new TipoSetorModel({}),
      opcoes: {
        posicoes: helpers.TipoSetorEnum,
      },
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.tipo_setor.titulos.editar');
      return this.$t('modulos.tipo_setor.titulos.novo');
    },
  },
  mounted() {
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'TipoSetores', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'TipoSetores', 'Inserir');

    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      TipoSetoresService.buscar(this.id)
        .then((res) => {
          this.form = new TipoSetorModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.tipo_setor.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function () {
      if (this.$refs.form) this.valido = this.$refs.form.validate();
      if (!this.valido) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      TipoSetoresService.salvar(this.form)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.tipo_setor.cadastro_sucesso`));
          this.$router.push({ name: 'tipo-setores' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'tipo-setores' });
      });
    },
  },
};
</script>
